.page {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.product_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(200, 200, 200);
  transition: 0.25s;
  min-height: 125px;
  min-width: 125px;
  color: black;
  text-decoration: none;
  transition: 0.25s;
}

.product_card a{
  font-weight: bold;
}

.product_card:hover {
  box-shadow: 0px 0px 20px rgb(200, 200, 200);
}
